import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-buttons">
          <Link to="/fellow">
            <button className="footer-button">Become a Fellow</button>
          </Link>
          <Link to="/volunteer">
            <button className="footer-button">Become a Volunteer</button>
          </Link>
          <Link to="/partners">
            <button className="footer-button">Become a Partner</button>
          </Link>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; Propel2Excel 2024. All rights reserved.</p>
        <div className="footer-social">
          <a href="https://www.linkedin.com/company/propel2excel/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} style={{ color: '#fff' }} />
          </a>
          <a href="https://www.instagram.com/propel2excel/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} style={{ color: '#fff' }} />
          </a>
          <a href="https://x.com/Propel2Excel" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} style={{ color: '#fff' }} />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/admin-login">
            <button className="footer-admin-button">Admin Login</button>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
